<template>
    <div class="login-form">
        <h1>欢迎登录后台</h1>
        <el-form :model="formData" ref="formRef" :rules="formDataRule">
            <el-form-item prop="adminName">
                <el-input type="text" required name="adminName" v-model="formData.adminName" @click="inputClickValidate('adminName', formRef)" placeholder="输入账号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" required name="password" v-model="formData.password" @click="inputClickValidate('password', formRef)" placeholder="输入密码"></el-input>
            </el-form-item>
            <el-form-item prop="verifyCode">
                <el-input type="text" required name="verifyCode" v-model="formData.verifyCode" @click="inputClickValidate('verifyCode', formRef)" placeholder="输入验证码">
                    <template #append><img class="verify-image" alt="验证码" @click="changeVerifyCode" :src="verifyCodeUrl" /></template>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="formSubmit($event)">登 录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
    import { reactive,ref } from 'vue';
    import inputClickValidate from '@/hooks/inputClickValidate';
    import { useStore } from 'vuex';
    import axios from 'axios';

    //点击刷新验证码图片
    const verifyCodeUrl = ref('/ajax/mms/login/getVerifyImage');
    function changeVerifyCode() {
        verifyCodeUrl.value = verifyCodeUrl.value + '?' + Date.now();
    }

    //表单数据对象
    const formData = reactive({
        adminName: '',
        password: '',
        verifyCode: ''
    });

    //表单验证规则
    const formDataRule = {
        adminName: {
            type: 'string',
            required: true,
            trigger: 'change',
            message: '请输入账号'
        },
        password: {
            type: 'string',
            required: true,
            trigger: 'change',
            message: '请输入密码'
        },
        verifyCode: {
            type: 'string',
            required: true,
            trigger: 'change',
            message: '请输入验证码'
        }
    }

    //从vuex获取错误提示相关参数
    const store = useStore();

    //表单ref
    const formRef = ref(null);

    //表单提交函数
    function formSubmit(event) {
        //全表单验证，并提交数据
        formRef.value.validate((valid) => {
            if(valid) {
                //表单验证成功，ajax验证
                //以表单的形式发送数据
                axios.defaults.headers['content-type'] = 'application/x-www-form-urlencoded';
                axios.post('/ajax/mms/login/loginValidation', formData).then(
                    response => {
                        console.log(response.data);
                        //依据验证结果进行操作
                        if(response.data.isPass) {
                            //账号密码验证成功
                            //跳转
                            location.href = '/mms/center';
                        } else {
                            //登录失败
                            //显示错误提示
                            store.commit('ChangeIsShowAlert', true);
                            store.commit('ChangeAlertMessage', response.data.message);
                            
                            //自动关闭alert
                            alertAutoClose();
                        }
                    },
                    error => {
                        //显示错误提示
                        store.commit('ChangeIsShowAlert', true);
                        store.commit('ChangeAlertMessage', error.message);

                        //自动关闭alert
                        alertAutoClose();
                    }
                );
            } else {
                //表单验证失败
                event.preventDefault();
            }
        });
    }

    //自动关闭alert
    function alertAutoClose() {
        setTimeout(() => {
            store.commit('ChangeIsShowAlert', false);
        }, 10000);
    }
</script>

<style scoped>
    .login-form {
        background-color: white;
        width: 400px;
        height: 384px;
        padding-top: 67px;
        color: #333333;
        float: right;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
    }
    .login-form h1 {
        background-image: url('@/assets/svg/mms/login-form-smile.svg');
        background-size: 35px 31px;
        background-repeat: no-repeat;
        font-size: 24px;
        line-height: 31px;
        padding-left: 45px;
        width: 149px;
        font-weight: 400;
        margin: 0px auto;
        margin-bottom: 38px;
    }

    .el-form {
        width: 400px;
        height: 231px;
    }
    .el-input,
    .el-button {
        width: 314px;
        height: 40px;
        margin: 0 auto;
    }
    :deep(.el-input__wrapper) {
        border-radius: 0px;
        height: 36px;
    }
    :deep(.el-input-group__append) {
        width: 100px;
        height: 38px;
        min-height: 38px;
        padding: 0;
        border: 0.0625rem solid #DFE6EC;
        border-left: none;
        border-radius: 0;
    }
    .verify-image {
        width: 100px;
        height: 38px;
    }
    .el-button {
        border-radius: 0;
        background-color: #1890FF;
        color: #fff;
        border-color: #1890FF;
    }
    .el-button:hover {
        opacity: 0.9;
    }
    .el-form-item {
        margin-bottom: 32px;
        height: 38px;
    }
</style>
  